.year-select {
    color: white;
    font-weight: bold;
    background: none;
    align-items: left;
}

.year-select select {
    color: white;
    background: none;
    border: none;
    margin: 0 0 0 0.5rem;
}

.year-select select:focus {
    outline: none;
}

.year-select label {
    font-weight: normal;
}

@media only screen and (max-device-width: 480px) {
    .year-select select {
        border: 1px solid #ccc;
        border-radius: 2px;
        -webkit-appearance: auto;
    }
}