body {
  height: 100vh;
  height: 100lvh;
}

h1,
h3 {
  text-align: center;
  font-weight: 300;
  color: #000;
}

h3 {
  font-size: 16px;
  padding-top: 16px;
  padding-bottom: 3px;
  margin-top: 0;
  border-top: 1px solid #fff;
}

h1 {
  padding-bottom: 9px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 0;
}

.bg-color1 {
  background-color: #f0f0f0;
}

.container {
  margin: 10px auto 40px;
  max-width: 840px;
}

.navBar {
  display: flex;
  justify-content: space-between;
}

.inner-cont {
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.form-group {
  position: relative;
}

.currency {
  position: absolute;
  right: 22px;
  top: 36px;
  font-size: 25px;
}

.form-control,
.form-control:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.pay-input {
  font-size: 60px;
  height: auto;
  padding: 0 47px 0 10px;
  margin: 0;
  text-align: right;
  color: #2A353D;
  border-radius: 0;
}

.col-md-3,
.col-md-6,
.col-sm-4,
.col-sm-8,
.col-sm-12 {
  padding: 0;
}

.box {
  position: relative;
  margin: 5px 5px 0px;
  padding: 10px 15px;
}

.box .box-content {
  font-family: 'Slabo 27px', 'Lato';
  color: #2A353D;
}

.main-box .box-label {
  text-align: center;
  height: 120px;
  padding: 10px;
}

.xs-box .box-content {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 38px;
}

.s-box .box-label {
  font-weight: bold;
  font-size: 15px;
}

.s-box .box-content {
  font-size: 40px;
  line-height: 33px;
  margin-top: 4px;
}

.s-box .large-nb {
  font-size: 33px;
  line-height: 35px;
}

.s-box .box-content span {
  font-size: 20px;
  margin-left: 2px;
}

.skat-box {
  margin-bottom: 5px;
}

.skat-box .box-content {
  text-align: right;
  color: #A3451C;
  margin-top: 0;
}

.result-box .box-content {
  font-size: 78px;
  line-height: 67px;
  margin-top: 6px;
  padding-right: 0;
  text-align: right;
  color: #2E886C;
}

.result-box .box-content span {
  font-size: 33px;
}

.box-subtext {
  font-size: 13px;
}

.description {
  text-align: center;
  max-width: 600px;
  margin: 35px auto 30px;
  color: #e0e0e0;
  font-size: 12px;
}

.note {
  font-size: 12px;
}

.tooltipelement {
  vertical-align: middle;
  line-height: 16px;
}

.tooltip {
  max-width: 200px;
}

@media (min-width: 768px) {
  .container {
    margin-top: 20px;
  }

  .inner-cont {
    margin: 0 60px;
  }

  .box {
    margin: 5px;
  }

  .xs-box .box-content {
    position: static;
  }

  .skat-box {
    height: 149px;
    margin-bottom: 5px;
  }

  .skat-box .box-content {
    font-size: 88px;
    line-height: 84px;
    text-align: center;
  }

  .skat-box .box-content span {
    font-size: 33px;
  }

  .result-box {
    height: 149px;
  }

  .result-box .box-content {
    font-size: 88px;
    line-height: 67px;
    margin-top: 6px;
    padding-right: 10px;
    text-align: right;
    color: #2E886C;
  }

  .result-box .box-content span {
    font-size: 33px;
  }

  .tooltip {
    max-width: 400px;
  }
}

@media (min-width: 992px) {
  .container {
    margin-top: 55px;
  }

  .inner-cont {
    margin: 0 90px;
  }
}