.language-selector {
  text-align: right;
  color: white;
  margin: 0 -5px 5px;
}

.language-selector a {
  color: #e7f7ff;
  margin: 0 5px;
}

a.active {
  font-weight: bold;
  color: white;
}

a.active:hover,
a.active:focus {
  text-decoration: none;
}