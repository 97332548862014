.credits {
    text-align: center;
    margin: 30px auto 35px;
    color: #fff;
    font-size: 12px;
}

.credits .by {
    font-style: italic;
}

.credits .name {
    font-size: 14px;
    font-weight: bold;
}

.credits a {
    color: #fff;
    text-decoration: underline;
}

.credits a:hover {
    color: #a0a0a0;
}

.credits .text-medium {
    font-size: 14px;
}

.work {
    margin-top: 20px;
    color: #fff;
}

.services-cont {
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.services-cont .service {
    background-color: rgba(52, 52, 52, 0.4);
    padding: 13px 0;
    margin: 5px;
}

.services-cont .service-name a {
    font-size: 14px;
    padding: 0 5px 8px;
    display: block;
}

.services-cont .service-desc {
    margin: 0 15px;
}